.main-layout {
  width: 100%;
  min-height: 100vh;
  // background-color: #f4f4f4;
  overflow-x: hidden;
}

.bg-image {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.bg-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../../assets/fundo_lab_2.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(2px);
  z-index: -1;
}