.question-bar {
    min-height: 50px;
    height: "auto";
    padding: 0 5px 0 5px;
    background-color: #fff;
    border: 2px solid #e9e9e9;
    border-radius: 25px;
    flex-shrink: 0;

    @media only screen and (max-width: 600px) {
        padding: 0px 0px 2px 0px;
    }

    & .MuiOutlinedInput-notchedOutline {
        border: unset;
    }

    & .__input {
        flex-grow: 1;
        width: 100%;
        max-width: calc(100% - 100px);

        &.MuiTextarea-root {
            --Textarea-focusedHighlight: transparent;
        }

        @media only screen and (max-width: 400px) {
            width: 1%;
            overflow: hidden;
        }
    }


    & .__button {
        &:hover {
            background-color: unset;
        }

        &.--no-padding {
            padding: 0;
        }

        &.--blue-background {
            background-color: #1976d2;
        }

        &.--red-background {
            background-color: #eb5f5f;
        }

        &.--white-background {
            background-color: #fff;
        }
    }

    & .send-icon {
        font-size: 20px;
        color: #fff;
    }

    & .record-icon {
        font-size: 20px;
        color: #fff;
    }

    & .discard-icon {
        font-size: 20px;
        color: #fff;
    }

    & canvas {
        flex-grow: 1;
        width: 100%;
        max-width: calc(100% - 100px); /* Garante que o canvas respeite o espaço dos botões */
        height: auto;
    }
}