.video-container {
    position: absolute;
    left: 2%;
    top: 0;
    width: 250px;
    height: 250px;
    z-index: 1000;
    overflow: hidden;
    border-radius: 50%;
    background-color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 1000px) {
        width: 150px;
        height: 150px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    &.no-avatar {
        background-color: transparent;

        .icon-button {
            transform: scale(1.5);
        }

        .MuiSvgIcon-root {
            font-size: 3rem;
        }
    }

    .icon-button {
        transition: transform 0.3s ease-in-out;
    }
}

.video-loading {
    position: absolute;
    z-index: 1001;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.video-content {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.close-button {
    margin-top: 16px;
    width: 100%;
}