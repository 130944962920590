.main-content {
    width: 100%;
    height: calc(100vh - 70px);
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // background-color: #fff;

    @media only screen and (max-width: 600px) {
        height: 85vh;
    }

    @media only screen and (max-width: 400px) {
        height: 90vh;
    }
}