.messages-section {
    &>div:last-child {
        margin-bottom: 40px;
    }
}

.fade-out {
    opacity: 0;
    transition: opacity 0.3s ease-out;
}

.fade-in {
    opacity: 1;
    transition: opacity 0.3s ease-in;
}

.loading-dots {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dot {
    width: 8px;
    height: 8px;
    margin: 0 4px;
    background-color: #333;
    border-radius: 50%;
    animation: bounce 1.5s infinite ease-in-out;
}

.dot:nth-child(1) {
    animation-delay: 0s;
}

.dot:nth-child(2) {
    animation-delay: 0.3s;
}

.dot:nth-child(3) {
    animation-delay: 0.6s;
}

@keyframes bounce {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
}

.messages-container {
    height: 100%;
    padding: 100px 0 30px 0;

    @media only screen and (max-width: 1000px) {
        padding: 160px 0 30px 0;
    }
}